<template>
  <SumHeaderTable
    :value="targets"
    :global-filter-fields="['targetingText']"
    :columns="columns"
    :sums="sums"
    table-prefix="general-v1"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import SumHeaderTable from '../../../../SumHeaderTable.vue';
import generateCommonColumns from '../../../../../utils/tableUtils';
import { formatBid } from '../../../../../utils/formatUtils';

export default {
  components: { SumHeaderTable },
  props: {
    targets: {
      type: Array,
      required: true,
    },
    // Changes the targetingText header to indicate that targets are automatic
    autoTargets: {
      type: Boolean,
      default: false,
    },
    sums: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      columns: [
        {
          id: 'targetingText',
          field: 'targeting',
          header: this.autoTargets ? 'Automatic Targeting Group' : 'Target',
          sortable: true,
          numeric: false,
          decimal: false,
          visible: true,
          notInFilter: true,
          alwaysVisible: true,
          width: '250px',
          content: {
            center: false,
            to: null,
            badge: null,
            format: null,
          },
        },
        {
          id: 'targetState',
          field: 'state',
          header: 'Status',
          sortable: true,
          numeric: false,
          decimal: false,
          visible: true,
          alwaysVisible: true,
          notInFilter: true,
          width: '100px',
          content: {
            center: false,
            to: null,
            badge: true,
            format: null,
          },
        },
        {
          id: 'targetBid',
          field: 'keywordBid',
          header: 'Bid',
          sortable: true,
          numeric: false,
          decimal: true,
          visible: true,
          alwaysVisible: true,
          notInFilter: true,
          width: '90px',
          content: {
            center: true,
            to: null,
            remove: '$',
            badge: null,
            format: (data) => formatBid(data, this.$store.state.auth.selectedProfile.currencyCode),
          },
        },
        ...generateCommonColumns(() => this.$store.state.auth.selectedProfile),
      ].map((column, index) => ({ ...column, order: index })),
    };
  },
  computed: {
    ...mapGetters(['royalties']),
  },

};
</script>

<style scoped lang="scss">
@import "../../../../../assets/styles/dashboard/campaigns.scss";
</style>
